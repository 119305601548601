import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { Subject } from 'rxjs';
import {preloader} from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loading: any;
  myVar: Subject<string> = new Subject<string>();

  constructor(public loadingController: LoadingController, public toastCtrl: ToastController) { }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      duration: 5000,
      spinner:'crescent',
      cssClass : 'loadingCss',
      backdropDismiss: true
    });
    await this.loading.present();
  }
  async dismissLoading() {
    await this.loading.dismiss();
  }
  async presentToast(message) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 2000,
      cssClass : 'toastCss'
    });
    toast.present();
  }

  emailValidation(mail_id) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(mail_id).toLocaleLowerCase()))
      return true;
    else
      return false;
  }
  mobileNumberValidation(mobile_no) {
    const numeber = /^[0]?[6789]\d{9}$/;
    if (numeber.test(mobile_no))
      return true;
    else
      return false;
  }
  passwordValidation(password) {
    const pass = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    if (pass.test(password))
      return true;
    else
      return false;
  }
  usernameValidation(name) {
    const username = /^[a-zA-Z]+$/;
    if (username.test(name))
      return true;
    else
      return false;
  }
}
