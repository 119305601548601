import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class Config {
    public value = 2;
}

export const logo = '"../../assets/images/landing.svg';

export const preloader = '<img src="../assets/images/loader.gif">';

export const currency = "INR";


//URL
export const url = "https://api.batzone11.com/api/";
export const aws_url = "https://batzone.s3.ap-south-1.amazonaws.com/FinalPlayer11Json/";
export const payment_url = "https://api.batzone11.com/pay/deposite";
export const ifscUrl = "https://ifsc.razorpay.com/";
export const appVersion = "1.1";
